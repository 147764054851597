/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'

export interface ContextType { 
  isActive: boolean
  account: string | null | undefined
  isLoading: boolean
  mustChangeChain: boolean
  provider: any
  setIsActive: (isActive: boolean) => void
  connect: () => Promise<void>
  disconnect: () => Promise<void>
}

export const SUPPORT_CHAINS = [56];

export const MataMaskContext = React.createContext<ContextType | null>(null)

export const injected = new InjectedConnector({ supportedChainIds: SUPPORT_CHAINS })

export const MetaMaskProvider: React.FC = ({ children }) => {
  const { activate, account, active, deactivate } = useWeb3React()

  const [isActive, setIsActive] = useState(false)
  const [mustChangeChain, setMustChangeChain] = useState(false)

  const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to MetaMask
  const [isLoading, setIsLoading] = useState(true)
  const [provider, setProvider] = useState<string>()

  // Check when App is Connected or Disconnected to MetaMask
  const handleIsActive = useCallback(() => {
      setIsActive(active)
  }, [active])

  useEffect(() => {
      handleIsActive()
  }, [handleIsActive])

  // Connect to MetaMask wallet
  const connectToChain = async () => {
    try {
    await (window as any).ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${SUPPORT_CHAINS[0].toString(16)}` }],
    });
    } catch (e: any) {
        if (e.code === 4902) {
          try {
            await (window as any).ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${SUPPORT_CHAINS[0].toString(16)}`,
                  chainName: 'Smart Chain - Mainnet',
                  nativeCurrency: {
                    name: 'Binance',
                    symbol: 'BNB', // 2-6 characters long
                    decimals: 18
                  },
                  blockExplorerUrls: ['https://bscscan.com'],
                  rpcUrls: ['https://bsc-dataseed.binance.org/'],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
    }
  }

  const connect = async () => {
      await connectToChain()
      
      setShouldDisable(true)
      setMustChangeChain(false)
      try {
          await activate(injected).then((val) => {
              setShouldDisable(false)
          })
      } catch(error) {
          console.log('Error on connecting: ', error)
      }
  }

  // Disconnect from Metamask wallet
  const disconnect = async () => {
      try {
          await deactivate()
      } catch(error) {
          console.log('Error on disconnnect: ', error)
      }
  }

  const values = useMemo(
      () => ({
          isActive,
          account,
          isLoading,
          connect,
          disconnect,
          setIsActive,
          shouldDisable,
          provider,
          mustChangeChain
      }),
      [isActive, isLoading, shouldDisable, account, provider, mustChangeChain]
  )

  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(true)
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injected)
        }
      })
      .catch(() => {
        setLoaded(true)
      })
  }, [activateNetwork, networkActive, networkError])
  if (loaded) {
    return <MataMaskContext.Provider value={values}>{children}</MataMaskContext.Provider>
  }
  return <>Loading</>
}

export const useMetaMask = () => {
  const context = useContext(MataMaskContext)

  if(!context) {
    throw new Error('useMetaMask hook: Not have context')
  }

  return context
}